import faceView from "../../images/guide/full.webp";
import sideView from "../../images/guide/sideView.webp";
import { Button } from "antd";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import AppContext from "../../utilities/contexts/appContext";
import { fileToBase64 } from "../../utilities/helper";
import MeasurementContext from "../../utilities/contexts/measurementContext";
import { useTranslation } from "react-i18next";
import BodyMeasurementCamera from "../modal/BodyMeasurementCamera";

const ChooseFile = () => {
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const measurementContext = useContext(MeasurementContext);
  const [showCamera, setShowCamera] = useState(false);

  useEffect(() => {
    if (!measurementContext.hasCameraAccess) {
      appContext.handleStep(0);
      setShowCamera(false);
      appContext.handleCapturing(true);
    }
  }, [measurementContext.hasCameraAccess]);

  const handleCameraModal = () => {
    setShowCamera(true);
    appContext.handleCapturing(false);
  };

  const handleChooseFile = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const files = (e.target as HTMLInputElement).files;

    if (files) {
      fileToBase64(files[0], function (base64Data) {
        if (appContext.step === 0) {
          measurementContext.handleChangeFirstPhoto(base64Data);
          appContext.handleStep(-1);
        } else {
          if (measurementContext.firstPhoto) {
            measurementContext.handleChangeSecondPhoto(base64Data);
            measurementContext.handleMeasurement(measurementContext.firstPhoto, base64Data, 2);
          }
        }
      });
    }
  };

  if (showCamera && appContext.step === 0) {
    return <BodyMeasurementCamera />;
  }

  return (
    <div className="w-full h-screen pt-[3.75rem] z-30 fixed bottom-0 top-0 left-0 bg-p">
      <div className="flex justify-center pt-[3.75rem] items-center flex-col">
        <img src={appContext.step === 0 ? faceView : sideView} alt="" className="max-h-[21.875rem] mt-8" />

        {appContext.step === 0 ? (
          <p className="px-5 mt-6 text-sm text-center text-gray-600 ">{t("measurement.full")}</p>
        ) : (
          <p className="px-5 mt-6 text-sm text-center text-gray-600 ">{t("measurement.side")}</p>
        )}
        {measurementContext.cameraErrorMessage && (
          <div className="mt-4">
            <p className="text-sm text-center text-red-500">{measurementContext.cameraErrorMessage}</p>
          </div>
        )}
        <div className={` px-7  flex-wrap flex justify-center items-center  mx-auto w-full bg-white fixed bottom-0  left-0 py-3 `}>
          <div className={`w-full gap-4  grid ${appContext.step === -1 || !measurementContext.hasCameraAccess ? "grid-cols-1" : "grid-cols-2"} lg:w-5/12 xl:w-4/12 `}>
            <Button size="large" type="link">
              <label className="flex items-center justify-center btn btn-link" htmlFor="choose_from_gallery">
                <span>{t("measurement.gallery")}</span>
              </label>
            </Button>
            <input id="choose_from_gallery" onChange={handleChooseFile} className="hidden" type="file" accept="image/*" />
            {appContext.step !== -1 && measurementContext.hasCameraAccess && (
              <Button type="primary" size="large" onClick={handleCameraModal} className="w-full text-lg btn-primary ">
                {t("measurement.take")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChooseFile;
