import React, { useContext, useEffect, useRef, useState } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { DeviceOrientationState } from "../utilities/hooks/useDeviceOrientation";

import MeasurementCameraContext from "../utilities/contexts/measurementCameraContext";
import MeasurementContext from "../utilities/contexts/measurementContext";

interface Props {
  onSuccess: Function;
  onError: Function;
  onNotSupport?: Function;
  message?: string | null;
  type: string;
  orientation: DeviceOrientationState;
  isPermissionGranted: boolean;
  isSupported: boolean;
  isSDK?: boolean;
}

const defaultMessage = "Place your mobile on the table at 90 degrees";

const StandMobileDegree = (props: Props) => {
  const { type, onSuccess, onError, onNotSupport, orientation, isSupported, isPermissionGranted, message = defaultMessage, isSDK = false } = props;
  const [hasError, setHasError] = useState(false);
  const degreeWrapperRef = useRef<HTMLDivElement | null>(null);
  const switcherRef = useRef<HTMLDivElement | null>(null);
  const [showMessage, setShowMessage] = useState(true);
  const minDegree = 80;
  const maxDegree = 100;
  const [status, setStatus] = useState("");
  const measurementContext = useContext(MeasurementContext);
  const measurementCameraContext = useContext(MeasurementCameraContext);

  useEffect(() => {
    switch (status) {
      case "error": {
        console.log("error");
        if (isSDK) {
          measurementCameraContext.handlePlaySound(3);
        } else {
          measurementContext.handlePlaySound(3);
        }
        break;
      }

      case "success": {
        console.log("success");
        if (type === "front") {
          if (isSDK) {
            measurementCameraContext.handlePlaySound(1);
          } else {
            measurementContext.handlePlaySound(1);
          }
        } else {
          if (isSDK) {
            measurementCameraContext.handlePlaySound(2);
          } else {
            measurementContext.handlePlaySound(2);
          }
        }
        break;
      }
    }
  }, [status, type]);

  useEffect(() => {
    if (isPermissionGranted && isSupported) {
      handleOrientation(orientation);
    }
  }, [orientation, isPermissionGranted]);

  const handleOrientation = (event: DeviceOrientationState) => {
    const beta = event.beta;
    if (beta) {
      setHasError(false);
      if (switcherRef.current) {
        if (beta > maxDegree || beta < minDegree) {
          switcherRef.current.style.top = beta / 2 + "%";
          switcherRef.current.style.backgroundColor = "#d51e3e";
          setShowMessage(true);
          onError();
          setStatus("error");
        } else {
          switcherRef.current.style.backgroundColor = "#4ab764";
          switcherRef.current.style.top = beta / 2 + "%";
          setShowMessage(false);
          onSuccess();
          setStatus("success");
        }
      }
    } else {
      if (typeof onNotSupport === "function") {
        onNotSupport();
      }

      setHasError(true);
      setStatus("error");
    }
  };

  if (hasError) {
    return <></>;
  }

  return (
    <div ref={degreeWrapperRef} className={`h-screen relative overflow-hidden degree-wrapper `}>
      {showMessage && (
        <div className="fixed flex items-center justify-center w-full px-5 text-xl transform -translate-y-1/2 z-degree top-1/2">
          <p className="px-5 py-2 font-bold text-center text-white rounded-xl drop-shadow-2xl">{message}</p>
        </div>
      )}

      <div className="flex flex-col items-center justify-center gap-2 p-2 m-1 bg-white rounded-full camera-degree-controller bg-opacity-60">
        <div ref={switcherRef} className="degree-switcher">
          {showMessage ? <CloseOutlined className="text-white" /> : <CheckOutlined className="text-white" />}
        </div>

        <div className="w-4 h-4 bg-red-500 rounded-full opacity-50" />
        <div className="w-4 h-4 bg-red-500 rounded-full opacity-50" />
        <div className="w-4 h-4 bg-green-500 rounded-full opacity-50" />
        <div className="w-4 h-4 bg-red-500 rounded-full opacity-50" />
        <div className="w-4 h-4 bg-red-500 rounded-full opacity-50" />
      </div>
    </div>
  );
};
export default StandMobileDegree;

// import React, { useContext, useEffect, useRef, useState } from "react";
// import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
// import { DeviceOrientationState } from "../utilities/hooks/useDeviceOrientation";

// import MeasurementCameraContext from "../utilities/contexts/measurementCameraContext";
// import MeasurementContext from "../utilities/contexts/measurementContext";
// import { useSoundPlayer } from "../utilities/hooks/useSoundPlayer";

// interface Props {
//   onSuccess: Function;
//   onError: Function;
//   onNotSupport?: Function;
//   message?: string | null;
//   type: string;
//   orientation: DeviceOrientationState;
//   isPermissionGranted: boolean;
//   isSupported: boolean;
//   isSDK?: boolean;
// }

// const defaultMessage = "Place your mobile on the table at 90 degrees";

// const StandMobileDegree = (props: Props) => {
//   const { type, onSuccess, onError, onNotSupport, orientation, isSupported, isPermissionGranted, message = defaultMessage, isSDK = false } = props;
//   const [hasError, setHasError] = useState(false);
//   const degreeWrapperRef = useRef<HTMLDivElement | null>(null);
//   const switcherRef = useRef<HTMLDivElement | null>(null);
//   const [showMessage, setShowMessage] = useState(true);
//   const minDegree = 80;
//   const maxDegree = 100;
//   const [status, setStatus] = useState("");

//   const playSound = useSoundPlayer(isSDK);

//   useEffect(() => {
//     switch (status) {
//       case "error": {
//         console.log("error");
//         playSound(3);
//         break;
//       }
//       case "success": {
//         console.log("success");
//         playSound(type === "front" ? 1 : 2);
//         break;
//       }
//     }
//   }, [status, type, playSound]);

//   useEffect(() => {
//     if (isPermissionGranted && isSupported) {
//       handleOrientation(orientation);
//     }
//   }, [orientation, isPermissionGranted]);

//   const handleOrientation = (event: DeviceOrientationState) => {
//     const beta = event.beta;
//     if (beta) {
//       setHasError(false);
//       if (switcherRef.current) {
//         if (beta > maxDegree || beta < minDegree) {
//           switcherRef.current.style.top = beta / 2 + "%";
//           switcherRef.current.style.backgroundColor = "#d51e3e";
//           setShowMessage(true);
//           onError();
//           setStatus("error");
//         } else {
//           switcherRef.current.style.backgroundColor = "#4ab764";
//           switcherRef.current.style.top = beta / 2 + "%";
//           setShowMessage(false);
//           onSuccess();
//           setStatus("success");
//         }
//       }
//     } else {
//       if (typeof onNotSupport === "function") {
//         onNotSupport();
//       }

//       setHasError(true);
//       setStatus("error");
//     }
//   };

//   if (hasError) {
//     return null;
//   }

//   return (
//     <div ref={degreeWrapperRef} className={`h-screen relative overflow-hidden degree-wrapper `}>
//       {showMessage && (
//         <div className="fixed flex items-center justify-center w-full px-5 text-xl transform -translate-y-1/2 z-degree top-1/2">
//           <p className="px-5 py-2 font-bold text-center text-white rounded-xl drop-shadow-2xl">{message}</p>
//         </div>
//       )}

//       <div className="flex flex-col items-center justify-center gap-2 p-2 m-1 bg-white rounded-full camera-degree-controller bg-opacity-60">
//         <div ref={switcherRef} className="degree-switcher">
//           {showMessage ? <CloseOutlined className="text-white" /> : <CheckOutlined className="text-white" />}
//         </div>

//         <div className="w-4 h-4 bg-red-500 rounded-full opacity-50" />
//         <div className="w-4 h-4 bg-red-500 rounded-full opacity-50" />
//         <div className="w-4 h-4 bg-green-500 rounded-full opacity-50" />
//         <div className="w-4 h-4 bg-red-500 rounded-full opacity-50" />
//         <div className="w-4 h-4 bg-red-500 rounded-full opacity-50" />
//       </div>
//     </div>
//   );
// };
// export default StandMobileDegree;
