import { TbCapture } from "react-icons/tb";
import React from "react";

interface Props {
  onClick: Function;
}

const CaptureButton = (props: Props) => {
  const { onClick } = props;
  const handleClick = () => {
    onClick();
  };
  return (
    <div className="absolute z-50 items-center justify-center hidden p-5 text-white rounded-full md:flex bg-primary bottom-10" onClick={handleClick}>
      <TbCapture className="text-xl" />
    </div>
  );
};
export default CaptureButton;
