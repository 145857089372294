import React, { useContext, useState } from "react";
import { Button, Form, Input, Modal, Typography } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import TopTitle from "../TopTitle";
import SdkApi from "../../utilities/Api/sdk/Api";
import SdkContext from "../../utilities/contexts/sdk/sdkContext";
import { isIOS } from "../../utilities/helper";
import { GoogleLogin } from "@react-oauth/google";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

interface SdkIntroductionProps {
  onComplete: () => void;
}

const SdkIntroduction: React.FC<SdkIntroductionProps> = ({ onComplete }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const sdkContext = useContext(SdkContext);
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const response = await SdkApi.post("/api/v1/app/auth/email/signin", values);
      if (response.data.ok) {
        sdkContext.handleLogin(response.data.data.access_token, sdkContext.callback, sdkContext.serviceType);
        navigate(`/${i18next.language}/dashboard`);
      } else {
        Modal.error({
          title: t("login.messages.error"),
          content: response.data.message,
        });
      }
    } catch (error) {
      Modal.error({
        title: t("login.messages.error"),
        content: t("login.messages.unknownError"),
      });
    } finally {
      setLoading(false);
    }
  };

  const handleContinueWithoutLogin = () => {
    onComplete();
  };

  const handleLoginClick = () => {
    setShowLoginModal(true);
  };

  return (
    <div className="w-full h-screen pt-[3.75rem] z-50 fixed bottom-0 top-0 left-0 bg-p">
      <div className="flex flex-col items-center justify-between h-full pt-10">
        <div className="flex flex-col items-center justify-center w-full">
          <TopTitle title={t("sdk.introduction.title")} disableBack={true} />
          <h1 className="hidden px-10 text-lg font-bold uppercase text-primary lg:block">{t("sdk.introduction.welcome")}</h1>
          <p className="px-5 text-center mb-7">{t("sdk.introduction.description")}</p>
          <div className="flex justify-center w-full p-3 overflow-hidden">
            <img className="max-w-[220px] rounded-xl" src="/path-image.png" alt="Introduction" />
          </div>
          <div className="px-5 mt-5">
            <p>{t("sdk.introduction.additionalInfo")}</p>
          </div>
        </div>

        <div className="sticky bottom-0 flex justify-center w-full px-4 py-3 bg-white shadow">
          <div className="grid w-full grid-cols-1 gap-3 md:w-full lg:w-5/12 xl:w-4/12">
            <Button onClick={handleContinueWithoutLogin} type="primary" size="large" className="w-full btn-primary">
              {t("sdk.introduction.continueWithoutLogin")}
            </Button>
            <Button onClick={handleLoginClick} type="default" size="large" className="w-full">
              {t("sdk.introduction.loginButton")}
            </Button>
          </div>
        </div>
      </div>

      <Modal visible={showLoginModal} onCancel={() => setShowLoginModal(false)} footer={null} centered className="sdk-login-modal" wrapClassName="modal-wrapper">
        <Title level={3} className="mb-6 text-center text-primary">
          {t("login.title")}
        </Title>

        <Form form={form} name="sdk_login" onFinish={onFinish} layout="vertical" className="space-y-4" size="large">
          <Form.Item name="email" rules={[{ required: true, type: "email", message: t("login.inputs.email.required") }]}>
            <Input prefix={<MailOutlined className="text-gray-400" />} placeholder={t("login.inputs.email.placeHolder")} className="rounded-md" />
          </Form.Item>

          <Form.Item name="password" rules={[{ required: true, message: t("login.inputs.password.required") }]}>
            <Input.Password prefix={<LockOutlined className="text-gray-400" />} placeholder={t("login.inputs.password.placeHolder")} className="rounded-md" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} className="w-full h-12 text-lg font-semibold">
              {t("login.button")}
            </Button>
          </Form.Item>

          {!isIOS() && (
            <GoogleLogin
              size="large"
              theme="outline"
              auto_select={false}
              shape="pill"
              ux_mode="popup"
              logo_alignment="center"
              containerProps={{
                className: "!p-0 !w-full google-container",
              }}
              text="continue_with"
              useOneTap={true}
              onSuccess={(credentialResponse) => {
                if (credentialResponse && credentialResponse.credential) {
                  sdkContext.handleLoading(true);
                  SdkApi.post(
                    "/api/v1/app/auth/google/join",
                    {
                      token: credentialResponse.credential,
                      auth_type: "google_auto_join",
                    },
                    {
                      headers: {
                        "Accept-Language": i18next.language,
                      },
                    }
                  )
                    .then((response) => {
                      if (response.data.ok) {
                        sdkContext.handleLoading(false);
                        sdkContext.handleLogin(response.data.data.access_token, sdkContext.callback, sdkContext.serviceType);
                        navigate(`/${i18next.language}/dashboard`);
                      } else {
                        sdkContext.handleLoading(false);
                        Modal.error({
                          centered: true,
                          title: "Error",
                          closable: true,
                          content: response.data.message,
                          okButtonProps: {
                            className: "btn-primary",
                          },
                          onOk: () => {},
                        });
                      }
                    })
                    .catch((e) => {
                      sdkContext.handleLoading(false);
                      Modal.error({
                        centered: true,
                        title: "Error",
                        closable: true,
                        content: e.response.data.message,
                        okButtonProps: {
                          className: "btn-primary",
                        },
                        onOk: () => {},
                      });
                    })
                    .finally(() => {
                      setLoading(false);
                      sdkContext.handleLoading(false);
                    });
                }
              }}
              onError={() => {}}
            />
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default SdkIntroduction;
