import SdkApi from "./Api";
import { AxiosError } from "axios";

interface Props {
  data: any;
  token: string | null;
}
async function SizeMeasurementApi({ token, data }: Props) {
  const response = await SdkApi.post("/api/v1/services/sizeMeasurement", data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  console.log(response.status);
  return response.data;
}

export default SizeMeasurementApi;
