import { useState } from "react";
import SdkContext from "../../utilities/contexts/sdk/sdkContext";
import { Outlet } from "react-router-dom";
import { Spin } from "antd";
import Loading from "../Loading";

const SdkLayout = () => {
  const [token, setToken] = useState<string | null>(localStorage.getItem("sdk_token") || null);
  const [loading, setLoading] = useState<boolean>(false);
  const [callback, setCallback] = useState<string | null>(localStorage.getItem("sdk_callback") || null);
  const [serviceType, setServiceType] = useState<string | null>(localStorage.getItem("sdk_service_type") || null);
  const handleLogin = (newToken: string, newCallback: string, newServiceType: string) => {
    setToken(newToken);
    setCallback(newCallback);
    setServiceType(newServiceType);
  };
  const handleLogout = () => {
    setToken(null);
    setCallback(null);
    setServiceType(null);
  };
  const handleLoading = (newLoading: boolean) => {
    setLoading(newLoading);
  };
  return (
    <SdkContext.Provider
      value={{
        token,
        loading,
        handleLogin,
        handleLogout,
        handleLoading,
        callback,
        serviceType,
      }}
    >
      {loading && <Loading />}
      <Outlet />
    </SdkContext.Provider>
  );
};
export default SdkLayout;
