import { isIOS } from "./helper";

export async function hasBackCamera(): Promise<boolean> {
  if (isIOS()) {
    return true;
  }

  if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    return false;
  }

  try {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter((device) => device.kind === "videoinput");
    if (videoDevices.length > 1) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.error("Error checking for back camera:", err);
    return false;
  }
}
