import React from "react";
import { Button, Typography } from "antd";
import { useTranslation } from "react-i18next";
import blurFaceSample from "../../images/Consent/blur-face-sample.png";
import TopTitle from "../TopTitle";

const { Paragraph } = Typography;

interface ConsentPageProps {
  onAccept: () => void;
  onDecline: () => void;
}

const ConsentPage: React.FC<ConsentPageProps> = ({ onAccept, onDecline }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-screen pt-[3.75rem] z-50 fixed bottom-0 top-0 left-0 bg-p">
      <div className="flex-col items-center justify-between h-full pt-10">
        <TopTitle title={t("consent.title")} disableBack={true} />

        <div className="flex flex-col items-center w-full h-full p-4">
          <div className="flex justify-center w-full p-3 overflow-hidden">
            <img className="max-h-[300px] rounded-xl" src={blurFaceSample} alt="Introduction" />
          </div>

          <Paragraph className="mb-6 text-center max-w-[300px]">{t("consent.description")}</Paragraph>
        </div>

        <div className="sticky bottom-0 flex justify-center w-full px-4 py-3 bg-white shadow">
          <div className="grid w-full grid-cols-2 md:w-full lg:w-5/12 xl:w-4/12 ">
            <Button onClick={onDecline} type="link" size="large" className="w-full ">
              {t("consent.decline")}
            </Button>
            <Button onClick={onAccept} type="primary" size="large" className="w-full btn-primary">
              {t("consent.accept")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsentPage;
