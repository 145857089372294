import React from "react";

interface measurementContextInterface {
  isLoading: boolean;
  firstPhoto: string | null;
  secondPhoto: string | null;
  handleChangeFirstPhoto: Function;
  handleChangeSecondPhoto: Function;
  handleLoading: Function;
  handleMeasurement: Function;
  handlePlaySound: Function;
  currentSound: Howl | null;
  sounds: Array<Howl>;
  hasCameraAccess: boolean;
  handleCameraAccess: Function;
  cameraErrorMessage: string | null;
  handleCameraErrorMessage: Function;
  cameraIsReady: boolean;
  handleCameraIsReady: Function;
}

const MeasurementContext = React.createContext<measurementContextInterface>({
  isLoading: false,
  hasCameraAccess: true,
  cameraErrorMessage: null,
  cameraIsReady: false,
  handleCameraErrorMessage: (value: string | null) => {},
  handleChangeFirstPhoto: (value: string) => {},
  handleChangeSecondPhoto: (value: string) => {},
  secondPhoto: null,
  firstPhoto: null,
  handleLoading: (value: boolean) => {},
  handleCameraIsReady: (value: boolean) => {},
  sounds: [],
  currentSound: null,
  handlePlaySound: (index: number) => {},
  handleCameraAccess: (value: boolean) => {},
  handleMeasurement: (firstPhoto: string, secondPhoto: string, nextStep?: number) => {},
});
export default MeasurementContext;
