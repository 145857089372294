import React, { useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
interface Props {
  count: number;
  onComplete: Function;
}
const Timer = (props: Props) => {
  const { count, onComplete } = props;
  const [timer, setTimer] = useState(count);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onComplete();
    }, count * 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer >= 1) {
        setTimer(timer - 1);
        if (timer - 1 === 0) {
        }
      }
    }, 1000);

    //Clearing the interval
    return () => {
      clearInterval(interval);
    };
  }, [timer]);
  if (timer === 0) {
    return null;
  }

  return (
    <>
      <div className="timer">
        <AiOutlineLoading3Quarters className="absolute z-40 w-full h-full drop-shadows-sm animate-spin" />
        <span className="absolute z-50 text-4xl font-bold drop-shadows-sm">{timer}</span>
      </div>
    </>
  );
};
export default Timer;
