import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import faceView from "../../images/guide/full.webp";
import sideView from "../../images/guide/sideView.webp";
import TopTitle from "../TopTitle";
import MeasurementCameraContext from "../../utilities/contexts/measurementCameraContext";
import { Howl } from "howler";
import timerAudioSrc from "../../sounds/Timer.mp3";
import fullViewCorrectAudioSrc from "../../sounds/fullView.wav";
import sideViewCorrectAudioSrc from "../../sounds/sideView.wav";
import degreeAudioSrc from "../../sounds/90Degrees.wav";
import { Button } from "antd";
import { fileToBase64, reduceBase64ImageSize } from "../../utilities/helper";
import DataContext from "../../utilities/contexts/sdk/dataContext";
import BodyMeasurementCamera from "./BodyMeasurementCamera";
import sdkContext from "../../utilities/contexts/sdk/sdkContext";
import Loading from "../Loading";
interface Props {
  type: "frontPose" | "sidePose";
}
const guideData = {
  frontPose: {
    image: faceView,
    title: "FRONT POSE",
    message: "Like the picture above, stand in front of the camera with your front side.",
  },
  sidePose: {
    image: sideView,
    title: "SIDE POSE",
    message: "Like the picture above, stand in front of the camera with your side profile.",
  },
};
const ChooseImage = (props: Props) => {
  const { type } = props;
  const [showCamera, setShowCamera] = useState(false);
  const [isGallery, setIsGallery] = useState<boolean | null>(null);
  const [sounds, setSounds] = useState<Howl[]>([]);
  const [currentSound, setCurrentSound] = useState<Howl | null>(null);
  const [hasCameraAccess, setCameraAccess] = useState(true);
  const [cameraErrorMessage, setCameraErrorMessage] = useState<string | null>(null);
  const [cameraIsReady, setCameraIsReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const dataContext = useContext(DataContext);

  useEffect(() => {
    const preloadSounds = [new Howl({ src: [timerAudioSrc] }), new Howl({ src: [fullViewCorrectAudioSrc] }), new Howl({ src: [sideViewCorrectAudioSrc] }), new Howl({ src: [degreeAudioSrc] })];
    setSounds(preloadSounds);
  }, []);

  useEffect(() => {
    dataContext.handleCapturing(cameraIsReady);
  }, [cameraIsReady]);

  const handleShowCamera = () => {
    setShowCamera(true);
  };

  const handleCameraErrorMessage = (value: string | null) => {
    console.log(value);
    setCameraErrorMessage(value);
    setShowCamera(false);
    setIsGallery(true);
    setCameraAccess(false);
  };

  const handleCameraAccess = (value: boolean) => {
    setCameraAccess(value);
  };

  const handlePlaySound = (index: number) => {
    if (currentSound) {
      currentSound.stop();
    }
    if (index >= 0) {
      const newSound = sounds[index];
      console.log(sounds[index], index);
      if (cameraIsReady) {
        newSound.play();
      }
      setCurrentSound(newSound);
    } else {
      setCurrentSound(null);
    }
  };

  const handleCameraIsReady = (value: boolean) => {
    setCameraIsReady(value);
  };

  const handleLoading = (value: boolean) => {
    setIsLoading(value);
  };

  const handleChooseFile = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const files = (e.target as HTMLInputElement).files;
    console.log(files);
    setIsLoading(true);
    if (files) {
      fileToBase64(files[0], function (base64Data) {
        console.log(base64Data);
        reduceBase64ImageSize(base64Data, 800, 0.9, function (newBase64: string) {
          console.log(newBase64);
          setIsLoading(false);
          setIsGallery(true);
          if (!dataContext.frontPoseImage) {
            dataContext.handleFrontPoseImage(newBase64);
          } else {
            dataContext.handleSidePoseImage(newBase64);
          }
        });
      });
    }
  };

  const handleReset = () => {
    setShowCamera(true);
    setIsGallery(null);
    setCameraErrorMessage(null);
    setCameraIsReady(false);
    dataContext.handleFrontPoseImage(null);
    dataContext.handleSidePoseImage(null);
    dataContext.handleReset();
  };

  const handleProcessing = (processing: boolean) => {
    setIsProcessing(processing);
    dataContext.handleCapturing(processing);
  };

  const TakePhotoComplete = (frontPose: string | null, sidePose: string | null) => {
    if (frontPose) {
      dataContext.handleFrontPoseImage(frontPose);
    } else if (sidePose) {
      dataContext.handleSidePoseImage(sidePose);
    }
  };

  return (
    <MeasurementCameraContext.Provider
      value={{
        currentSound,
        sounds,
        isLoading,
        cameraErrorMessage,
        hasCameraAccess,
        cameraIsReady,
        handleCameraIsReady,
        handleCameraAccess,
        handleCameraErrorMessage,
        handlePlaySound,
        handleLoading,
      }}
    >
      {isProcessing && <Loading />}
      {showCamera && !isProcessing && (
        <BodyMeasurementCamera
          onError={(value: string) => {
            handleCameraErrorMessage(value);
          }}
          onComplete={TakePhotoComplete}
          onReset={handleReset}
          onProcessing={handleProcessing}
          type={props.type}
        />
      )}
      {!showCamera && (
        <div className="pt-[60px] px-5  w-full flex flex-col items-center ">
          <TopTitle title={guideData[type].title} disableBack={true} />
          <img src={guideData[type].image} alt="" className="max-h-[350px] mt-[32px]" />
          <p className="px-5 mt-6 text-sm text-center text-gray-600">{guideData[type].message}</p>
          {cameraErrorMessage && <p className="p-2 px-5 mt-2 text-sm text-center text-red-500 bg-red-100 rounded-lg">{cameraErrorMessage}</p>}

          <div className={` px-7  flex-wrap flex justify-center items-center  mx-auto w-full  bg-white fixed bottom-0  left-0 py-3 `}>
            <div className={`w-full gap-4  grid ${hasCameraAccess && !isGallery ? "grid-cols-2 " : "grid-cols-1"} lg:w-5/12 xl:w-4/12 `}>
              <Button size="large" type="link">
                <label className="flex items-center justify-center btn btn-link" htmlFor="choose_from_gallery">
                  {!hasCameraAccess || isGallery ? <span>Choose from gallery</span> : <span>Gallery</span>}
                </label>
              </Button>
              <input id="choose_from_gallery" key={type} onChange={handleChooseFile} className="hidden" type="file" accept="image/*" />
              {!isGallery && hasCameraAccess && (
                <Button type="primary" size="large" onClick={handleShowCamera} className="w-full text-lg btn-primary ">
                  Take Photo
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </MeasurementCameraContext.Provider>
  );
};
export default ChooseImage;
