import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
}

const ContentWrapper = (props: Props) => {
  const { children, className } = props;
  return <div className={`pt-[70px] ${className}`}>{children}</div>;
};
export default ContentWrapper;
