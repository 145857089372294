import NotFoundImage from "../../../images/notfound.svg";

const SdkNotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <img className="w-[250px] lg:w-[350px] h-auto" src={NotFoundImage} alt="" />
      <h1 className="mt-5 text-2xl text-bold ">NOT FOUND</h1>
    </div>
  );
};
export default SdkNotFound;
