import { Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import sdkServices from "../../../utilities/defaults/sdk/SdkServices";
import SdkContext from "../../../utilities/contexts/sdk/sdkContext";
import { Spin } from "antd";

const Gate = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const callback = searchParams.get("callback");
  const serviceType = searchParams.get("serviceType");
  const sdkContext = useContext(SdkContext);
  const navigate = useNavigate();

  useEffect(() => {
    // if (token && callback && serviceType) {
    if (token && serviceType) {
      sdkContext.handleLogin(token, callback, serviceType);
      switch (serviceType) {
        case sdkServices.SIZE_MEASUREMENT: {
          sdkContext.handleLoading(false);
          localStorage.setItem("sdk_token", token);
          // localStorage.setItem("sdk_callback", callback);
          localStorage.setItem("sdk_service_type", serviceType);
          navigate("/sdk/sizeMeasurement");
          break;
        }
        default: {
          localStorage.clear();
          sdkContext.handleLoading(false);
          navigate("/sdk/accessDenied");
        }
      }
    } else {
      sdkContext.handleLoading(false);
      navigate("/accessDenied");
    }
  }, []);

  return (
    <div className="flex items-center justify-center w-full h-screen ">
      <Spin size="large" />
    </div>
  );
};
export default Gate;
