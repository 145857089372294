import ResultData from "../../utilities/types/ResultData";
import resultLineGray from "../../images/measurement/result-line-gray.svg";
import resultLineWhite from "../../images/measurement/result-line-white.svg";
// @ts-ignore
import anime from "animejs";
import { useEffect, useRef } from "react";

interface ResultCardProps {
  isActive: boolean;
  resultData: ResultData;
  onClick: Function;
}

const ResultCard = (props: ResultCardProps) => {
  const { resultData, isActive, onClick } = props;
  const lineRef = useRef<HTMLImageElement>(null);
  const cardRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (isActive) {
      anime({
        targets: lineRef?.current,
        offset: [0, 1],
        opacity: [0, 1],

        translateX: ["20px", "10px"], // Animation from right to left
        translateY: ["-20px", "0%"], // Animation from right to left
        easing: "easeInOutQuad", // Easing function
        duration: 1500,
      });
    }
  }, [isActive]);

  return (
    <div onClick={() => onClick()} ref={cardRef} className={`result-card relative z-50 ${isActive ? "active" : ""}`}>
      <div className="flex items-center justify-start px-3">
        <div className="icon-wrapper">{isActive ? resultData.activeIcon : resultData.icon}</div>
        <span className="result-title">{resultData.title}</span>
      </div>
      {isActive && <img ref={lineRef} className="line" src={resultLineWhite} alt="" />}
      {!isActive && <img className="line" src={resultLineGray} alt="" />}

      <span className="result-value">{resultData.value} cm</span>
    </div>
  );
};
export default ResultCard;
