import axios from "axios";
import { backUrl } from "./Urls";
import i18next from "i18next";

const getUserAddress = async (): Promise<string | null> => {
  try {
    const response = await axios.get("https://geolocation-db.com/json/");

    const { country_code } = response.data;
    return country_code;
  } catch (error) {
    console.error("Error fetching IP address: ", error);
    return null;
  }
};

const Api = axios.create({
  baseURL: backUrl + "/",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": i18next.language,
  },
});
const getDynamicBaseUrl = async () => {
  /*    const Code= await getUserAddress()

    if (Code==='IR') {
        return 'https://api.shaku.tech';
    } else {
        return 'https://api.shaku.tech';
    }*/
  return backUrl;
};

Api.interceptors.request.use(
  async (config) => {
    // Modify the request config here, e.g., add user-specific headers
    const newBaseUrl = await getDynamicBaseUrl(); // Implement this function to get the dynamic base URL

    if (newBaseUrl) {
      config.baseURL = newBaseUrl; // Set the new base URL
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status == 401 || error.response.status == 403) {
      console.log(error);
      localStorage.removeItem("token");
      if (window && window.location) {
        if (!window.location.href.includes("/login")) {
          window.location.href = "/" + i18next.language + "/guest";
        }
      }
    }

    // Handle error globally
    console.error("Request failed:", error);
    return Promise.reject(error);
  }
);

export default Api;
