// import TopTitle from "../TopTitle";
// import type { FormProps } from "antd";
// import { Button, Divider, Form, Input, InputNumber } from "antd";
// import { useContext } from "react";
// import DataContext from "../../utilities/contexts/sdk/dataContext";
// import ContentWrapper from "./ContentWrapper";

// type FieldType = {
//   height: number;
// };
// interface Props {
//   handleBack?: Function;
//   hasBack: boolean;
// }

// const GetHeight = ({ hasBack, handleBack }: Props) => {
//   const [form] = Form.useForm();
//   const dataContext = useContext(DataContext);
//   const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
//     dataContext.handleHeight(values.height);
//   };

//   const handleChange = (value: string | number | null) => {
//     const reg = /^\d*$/;
//     if (typeof value === "string") {
//       if (reg.test(value)) {
//         form.setFieldsValue({ number: value });
//       }
//     } else if (typeof value === "number") {
//       form.setFieldsValue({ number: value });
//     }
//   };
//   const handleSubmit = () => {
//     form.submit();
//   };
//   const handlePrev = () => {
//     if (typeof handleBack == "function") {
//       handleBack();
//     }
//   };

//   const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
//     console.log("Failed:", errorInfo);
//   };
//   return (
//     <div className="w-full mx-auto md:w-6/12 lg:w-4/12 xl:w-3/12 ">
//       <TopTitle title="INFORMATION" disableBack={true} />
//       <ContentWrapper>
//         <Form className="p-5 mx-5 rounded-lg " name="form" form={form} requiredMark={false} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off" layout="vertical">
//           {dataContext.frontPoseImage && dataContext.sidePoseImage && (
//             <div className="grid w-full grid-cols-2 gap-5 mb-5">
//               <img className="rounded-lg " src={dataContext.frontPoseImage} alt="" />
//               <img className="rounded-lg " src={dataContext.sidePoseImage} alt="" />
//             </div>
//           )}
//           <Form.Item<FieldType> label="Enter Your Height" name="height" rules={[{ required: true, message: "Please input your height!" }]}>
//             <InputNumber onChange={handleChange} size="large" className="w-full" addonAfter="cm" />
//           </Form.Item>
//           <Divider />
//         </Form>
//       </ContentWrapper>
//       <div className="fixed bottom-0 left-0 flex justify-center w-full px-4 py-3 bg-white shadow ">
//         <div className={`w-full md:w-full lg:w-5/12  xl:w-4/12  grid  ${hasBack ? "grid-cols-2" : "grid-cols-1"}`}>
//           {hasBack && (
//             <Button onClick={handlePrev} type="link" size="large" className="w-full ">
//               Back
//             </Button>
//           )}

//           <Button type="primary" onClick={handleSubmit} size="large" className="w-full btn-primary">
//             SUBMIT
//           </Button>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default GetHeight;

import React, { useContext } from "react";
import { Button, Divider, Form, InputNumber } from "antd";
import type { FormProps } from "antd";
import TopTitle from "../TopTitle";
import DataContext from "../../utilities/contexts/sdk/dataContext";
import ContentWrapper from "./ContentWrapper";

type FieldType = {
  height: number;
};
interface Props {
  handleBack?: () => void;
  hasBack: boolean;
  onSubmit: () => void;
}

const GetHeight: React.FC<Props> = ({ hasBack, handleBack, onSubmit }) => {
  const [form] = Form.useForm();
  const dataContext = useContext(DataContext);

  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    dataContext.handleHeight(values.height);
  };

  const handleFinish = (values: { height: number }) => {
    dataContext.handleHeight(values.height);
    onSubmit();
  };

  const handleChange = (value: string | number | null) => {
    const reg = /^\d*$/;
    if (typeof value === "string") {
      if (reg.test(value)) {
        form.setFieldsValue({ number: value });
      }
    } else if (typeof value === "number") {
      form.setFieldsValue({ number: value });
    }
  };
  const handleSubmit = () => {
    form.submit();
  };
  const handlePrev = () => {
    if (typeof handleBack == "function") {
      handleBack();
    }
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="w-full mx-auto md:w-6/12 lg:w-4/12 xl:w-3/12">
      <TopTitle title="INFORMATION" disableBack={!hasBack} />
      <ContentWrapper>
        <Form form={form} onFinish={handleFinish} layout="vertical" className="p-5 mx-5 rounded-lg">
          {dataContext.frontPoseImage && dataContext.sidePoseImage && (
            <div className="grid w-full grid-cols-2 gap-5 mb-5">
              <img className="rounded-lg" src={dataContext.frontPoseImage} alt="Front pose" />
              <img className="rounded-lg" src={dataContext.sidePoseImage} alt="Side pose" />
            </div>
          )}
          <Form.Item name="height" label="Enter Your Height" rules={[{ required: true, message: "Please input your height!" }]}>
            <InputNumber min={1} max={300} className="w-full" addonAfter="cm" />
          </Form.Item>
        </Form>
      </ContentWrapper>
      <div className="fixed bottom-0 left-0 flex justify-center w-full px-4 py-3 bg-white shadow">
        <div className={`w-full md:w-full lg:w-5/12 xl:w-4/12 grid ${hasBack ? "grid-cols-2" : "grid-cols-1"}`}>
          {hasBack && (
            <Button onClick={handleBack} type="link" size="large" className="w-full">
              Back
            </Button>
          )}
          <Button onClick={() => form.submit()} type="primary" size="large" className="w-full btn-primary">
            SUBMIT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GetHeight;
