import { Spin } from "antd";
interface Props {
  message?: string;
}
const Loading = (props: Props) => {
  return (
    <div className="fixed top-0 left-0 flex flex-col items-center justify-center w-full h-screen bg-p z-1000 ">
      {props.message && <span>{props.message}</span>}
      <Spin size="large" />
    </div>
  );
};
export default Loading;
