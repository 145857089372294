import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { Button, Divider, InputNumber, Modal, notification, Steps } from "antd";
import { ValueType } from "tailwindcss/types/config";
import AppContext from "../../utilities/contexts/appContext";
import Api from "../../utilities/Api/request";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import TopTitle from "../TopTitle";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Guide from "../Guide";
import fullView_1 from "../../images/guide/full_1.webp";
import fullView_2 from "../../images/guide/full_2.webp";
import sideView1 from "../../images/guide/side_1.webp";
import degreeAudioSrc from "../../sounds/90Degrees.wav";
import timerAudioSrc from "../../sounds/Timer.mp3";
import fullViewCorrectAudioSrc from "../../sounds/fullView.wav";
import sideViewCorrectAudioSrc from "../../sounds/sideView.wav";
import MeasurementContext from "../../utilities/contexts/measurementContext";
import ResultCard2 from "../../components/measurement/ResultCard2";
import ChooseFile from "../measurement/ChooseFile";
import { Howl } from "howler";
import ConsentPage from "../measurement/ConsentPage";

interface Props {
  demo?: boolean;
}

interface step {
  title: string;
  description?: string;
  image: string;
  texts: Array<string>;
}

const MeasurementGuid: Array<step> = [
  {
    title: "Stand Straight",
    description: "Before taking a photo, pay attention to the following points",
    image: fullView_1,
    texts: ["Place the camera on the table at 90 degrees", "Stand straight in front of the camera", "Capture your complete height"],
  },
  {
    title: "Front Pose",
    description: "Before taking a photo, pay attention to the following points",
    image: fullView_2,
    texts: ["Let your hands hang by your sides", "Don’t wear baggy clothes", "Don’t  hide your body shape in clothes"],
  },
  {
    title: "Side Pose",
    description: "Before taking a photo, pay attention to the following points",
    image: sideView1,
    texts: ["Do not stand at an angle", "Hang your arms on your thighs’ side"],
  },
];
const SizeMeasurement = (props: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const appContext = useContext(AppContext);
  const params = useParams();
  const [api, contextHolder] = notification.useNotification();
  const [bodyParts, setBodyParts] = useState<any>();
  const [disabled, setDisabled] = useState(params.personId ? location?.state?.height === null : appContext.user?.characteristic?.height === null);
  const [firstPhoto, setFirstPhoto] = useState<string | null>(null);
  const [secondPhoto, setSecondPhoto] = useState<string | null>(null);
  const [height, setHeight] = useState<ValueType | null>(params.personId ? location?.state?.height : appContext.user?.characteristic?.height);
  const [showGuide, setShowGuide] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [sounds, setSounds] = useState<Howl[]>([]);
  const [currentSound, setCurrentSound] = useState<Howl | null>(null);
  const [hasCameraAccess, setCameraAccess] = useState(true);
  const [cameraErrorMessage, setCameraErrorMessage] = useState<string | null>(null);
  const [showConsent, setShowConsent] = useState(false);
  const [cameraIsReady, setCameraIsReady] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const preloadSounds = [new Howl({ src: [timerAudioSrc] }), new Howl({ src: [fullViewCorrectAudioSrc] }), new Howl({ src: [sideViewCorrectAudioSrc] }), new Howl({ src: [degreeAudioSrc] })];
    setSounds(preloadSounds);
    // Clean up on unmount
    return () => {
      preloadSounds.forEach((sound) => sound.unload());
    };
  }, []);

  const handleCameraErrorMessage = (value: string | null) => {
    setCameraErrorMessage(value);
  };

  const handleCameraAccess = (value: boolean) => {
    setCameraAccess(value);
  };

  const playSound = (index: number) => {
    if (currentSound) {
      currentSound.stop();
    }
    if (index >= 0) {
      const newSound = sounds[index];
      if (cameraIsReady) {
        newSound.play();
      }
      setCurrentSound(newSound);
    } else {
      setCurrentSound(null);
    }
  };

  const handleLoading = (value: boolean) => {
    setLoading(value);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCameraIsReady = (value: boolean) => {
    setCameraIsReady(value);
  };

  const handleChangeHeight = (e: ValueType | null) => {
    setHeight(e);
    if (e !== null) {
      if (e.toString().length >= 2) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }
  };

  const handleMeasure = (fullImage: string, sideImage: string, nextStep?: any) => {
    appContext.handleLoading(true);
    appContext.handleStep(2);

    const data = {
      img_full_view_body: fullImage.split(",")[1],
      img_side_view_body: sideImage.split(",")[1],
      present_height: height,
      sub_user_id: params.personId,
    };

    const RequestUrl = props.demo ? "/api/v1/demo/services/sizeMeasurement" : "/api/v1/services/sizeMeasurement";
    Api.post<any>(RequestUrl, data, {
      headers: {
        "Accept-Language": i18next.language,
        Authorization: "Bearer " + appContext.token,
      },
    })
      .then((result) => {
        if (result.data && result.data.ok) {
          setBodyParts(result.data.data);

          if (nextStep) {
            appContext.handleStep(nextStep);
          } else {
            appContext.handleStep(2);
          }
        } else {
          handleRetry();
          api.error({
            message: <p className="w-full text-red-500">{result.data.message}</p>,
            placement: "topRight",
          });
        }
      })
      .finally(() => {
        appContext.handleLoading(false);
      });
  };

  const handleHeightSubmit = () => {
    setIsModalOpen(false);
    setShowConsent(true);
  };

  const handleConsentAccept = () => {
    setShowConsent(false);
    setShowGuide(false);
    appContext.handleCapturing(true);
  };

  const handleConsentDecline = () => {
    navigate("/");
  };

  const handleRetry = () => {
    setFirstPhoto(null);
    setSecondPhoto(null);
    setHeight(null);
    setBodyParts(null);
    setShowGuide(true);
    setShowConsent(false);
    setHeight(null);
    setIsModalOpen(true);
    setLoading(false);
    setCurrentSound(null);
    setCameraAccess(true);
    setCameraErrorMessage(null);
    setCameraIsReady(false);
    appContext.handleStep(0);
  };

  return (
    <MeasurementContext.Provider
      value={{
        handleMeasurement: handleMeasure,
        sounds,
        isLoading,
        handleLoading,
        firstPhoto,
        secondPhoto,
        handleChangeFirstPhoto: (value: string) => {
          setFirstPhoto(value);
        },
        handleChangeSecondPhoto: (value: string) => {
          setSecondPhoto(value);
        },
        handlePlaySound: playSound,
        currentSound: currentSound,
        handleCameraAccess,
        hasCameraAccess,
        cameraErrorMessage,
        handleCameraErrorMessage,
        cameraIsReady: cameraIsReady,
        handleCameraIsReady,
      }}
    >
      {contextHolder}

      {(appContext.step === 0 || appContext.step === -1) && !showGuide && (showConsent ? <ConsentPage onAccept={handleConsentAccept} onDecline={handleConsentDecline} /> : <ChooseFile />)}

      <Modal footer={[]} centered={true} open={isModalOpen} onOk={handleOk} closable={false}>
        <h1 className="pb-5 mb-5 text-lg font-bold text-center ">Enter Your Height</h1>

        <div className="flex flex-col w-full">
          <InputNumber
            type="tel"
            size="large"
            step={5}
            value={height}
            defaultValue={params.personId ? location?.state?.height : appContext.user?.characteristic?.height}
            onChange={handleChangeHeight}
            className="w-full"
            placeholder={t("measurement.inputs.height.placeholder")}
            addonAfter="cm"
          />
        </div>

        <Divider />

        <div>
          <Button
            disabled={height ? height.toString().length <= 1 : true}
            type="primary"
            className={`btn-primary w-full  ${!height || height.toString().length <= 1 ? "!opacity-30" : ""}`}
            onClick={handleHeightSubmit}
            size="large"
          >
            Submit
          </Button>
        </div>
      </Modal>

      <div className="w-full">
        {contextHolder}

        {appContext.step !== 2 && <TopTitle title={t("measurement.title")} disableBack={props.demo} />}

        {appContext.step === 0 && showGuide && (
          <Guide
            callback={() => {
              setShowGuide(false);
              appContext.handleCapturing(true);
            }}
            slides={MeasurementGuid}
          />
        )}

        {appContext.step === 2 && bodyParts && (
          <div className="w-full overflow-hidden">
            <ResultCard2 data={bodyParts.result} />

            <div className={`grid fixed z-50 bg-white lg:w-4/12 mx-auto bottom-0 left-0 lg:relative lg:bg-transparent shadow-none grid-cols-2 px-2 py-2 lg:mt-8 gap-3 w-full`}>
              <Button type="link" size="large" onClick={handleRetry} className="w-full lg:bg-white">
                {t("measurement.retryButton")}
              </Button>

              {props.demo && (
                <Link to={`/${i18next.language}/login`} className="w-full">
                  <Button type="primary" size="large" className="w-full bg-primary">
                    {t("measurement.loginButton")}
                  </Button>
                </Link>
              )}

              {!props.demo && (
                <Link to={params.personId ? `${i18next.language}/dashboard/persons/${params.personId}` : `/${i18next.language}/dashboard/`} className="w-full">
                  <Button type="primary" size="large" className="w-full bg-primary">
                    {t("measurement.dashboardButton")}
                  </Button>
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </MeasurementContext.Provider>
  );
};
export default SizeMeasurement;
