import Webcam from "react-webcam";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import AppContext from "../../utilities/contexts/appContext";
import { Button, message, Spin } from "antd";
import StandMobileDegree from "../StandMobileDegree";
import measurementOption from "../../utilities/types/bodyMeasurementCameraOptions";
import { Howl } from "howler";
import useDeviceOrientation from "../../utilities/hooks/useDeviceOrientation";
import MeasurementContext from "../../utilities/contexts/measurementContext";
import bodyMeasurementCameraOptions from "../../utilities/types/bodyMeasurementCameraOptions";
import degreeAudioSrc from "../../sounds/90Degrees.wav";
import timerAudioSrc from "../../sounds/Timer.mp3";
import fullViewCorrectAudioSrc from "../../sounds/fullView.wav";
import fullViewCorrectImage from "../../images/measurement/full_side.png";
import sideViewCorrectAudioSrc from "../../sounds/sideView.wav";
import sideViewCorrectImage from "../../images/measurement/half_side.png";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";
import CameraSwitchIcon from "../icons/CameraSwitchIcon";
import CaptureButton from "../measurementCamera/CaptureButton";
import { hasBackCamera } from "../../utilities/cameraUtils";
import useImageProcessor from "../../utilities/hooks/useImageProcessor";

const FACING_MODE_USER = "user";

const frontCameraOptions: bodyMeasurementCameraOptions = {
  title: "Front Pose",
  degreeAudioSrc: degreeAudioSrc,
  timerAudioSrc: timerAudioSrc,
  correctStandAudioSrc: fullViewCorrectAudioSrc,
  timerCount: 7,
  captureStartTime: 8,
  correctStandImage: fullViewCorrectImage,
  degreeText: "Please set your phone on 90Deg",
  hasDegree: true,
};

const sideCameraOptions: bodyMeasurementCameraOptions = {
  title: "Side Pose",
  degreeAudioSrc: degreeAudioSrc,
  captureStartTime: 8,
  timerAudioSrc: timerAudioSrc,
  correctStandAudioSrc: sideViewCorrectAudioSrc,
  timerCount: 7,
  correctStandImage: sideViewCorrectImage,
  degreeText: "Please set your phone on 90 Deg",
  hasDegree: true,
};

const BodyMeasurementCamera = () => {
  const { isPermissionGranted, requestPermission, isSupported, orientation } = useDeviceOrientation();
  const navigate = useNavigate();
  const measurementContext = useContext(MeasurementContext);
  const [frontImage, setFrontImage] = useState<string | null>(null);
  const [sideImage, setSideImage] = useState<string | null>(null);
  const [continueWithoutAccess, setContinueWithoutAccess] = useState(false);
  const [timeoutId, setTimeoutId] = useState<any>(null);
  const [showCaptureButton, setShowCaptureButton] = useState(!isPermissionGranted);
  const [accessLoading, setAccessLoading] = useState(false);
  const [currentType, setCurrentType] = useState("front");
  const [currentOptions, setCurrentOptions] = useState(frontCameraOptions);
  const [isLoading, setLoading] = useState(true);
  const [cameraError, setCameraError] = useState<null | string>(null);
  const [showImage, setShowImage] = useState(false);
  const [isCorrectStand, setIsCorrectStand] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [showDegree, setShowDegree] = useState(false);
  const [capturing, setCapturing] = useState(false);
  const [facingMode, setFacingMode] = useState<"user" | "environment">(FACING_MODE_USER);
  const [hasBackCam, setHasBackCam] = useState(false);

  const { processImage } = useImageProcessor();

  const webcamRef = useRef<Webcam>(null);

  const videoConstraints = {
    aspectRatio: 4 / 2,
    facingMode: facingMode === "user" ? facingMode : { exact: facingMode },
  };

  useEffect(() => {
    setAccessLoading(true);
    requestPermission().then((response) => {
      setAccessLoading(false);
      if (response === undefined) {
      }
    });
  }, []);

  useEffect(() => {
    async function checkBackCamera() {
      const result = await hasBackCamera();
      setHasBackCam(result);
    }
    checkBackCamera();
  }, []);

  useEffect(() => {
    if (isCorrectStand) {
      setShowImage(true);

      const id = setTimeout(() => {
        //timer start counting
        measurementContext.handlePlaySound(0);

        setShowTimer(true);
      }, currentOptions.captureStartTime * 1000);
      setTimeoutId(id);
    } else {
      // measurementContext.handlePlaySound(3);
      setShowTimer(false);
      setShowImage(false);
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null);
      }
    }
  }, [isCorrectStand, currentType]);

  useEffect(() => {
    if (frontImage) {
      setTimeout(() => {
        setCapturing(false);
      }, 300);
    }
  }, [frontImage]);

  useEffect(() => {
    if (frontImage && sideImage && !capturing) {
      measurementContext.handleMeasurement(frontImage, sideImage);
    }
  }, [sideImage, capturing]);

  useEffect(() => {
    if (currentType === "front") {
      setCurrentOptions(frontCameraOptions);
    } else {
      setCurrentOptions(sideCameraOptions);
    }
    setIsCorrectStand(false);
    setShowTimer(false);
    setShowImage(false);
  }, [currentType]);

  const toggleCamera = () => {
    setFacingMode((currentMode) => (currentMode === "user" ? "environment" : "user"));
  };

  const handleCapture = async () => {
    if (webcamRef && webcamRef.current) {
      const img = webcamRef.current?.getScreenshot() || "";
      if (currentType === "front") {
        setFrontImage(img);
        setCurrentType("side");
        setCurrentOptions(sideCameraOptions);
        setShowTimer(false);
        setIsCorrectStand(false);
      } else {
        setCapturing(true);

        await processAndSendImages(frontImage, img);
      }
    }
  };

  const processAndSendImages = async (frontImg: string | null, sideImg: string) => {
    if (!frontImg || !sideImg) {
      message.error("Error: Missing images. Please try again.");
      resetCapture();
      return;
    }

    try {
      const processFrontImg = await processImage(frontImg);

      setFrontImage(processFrontImg);
      setSideImage(sideImg);

      // const response = await sendImagesToServer(processedFrontImage, processedSideImage);

      // if (response.isValid) {
      //   measurementContext.handleMeasurement(processedFrontImage, processedSideImage);
      // } else {
      //   message.warning(response.message || "Images are not valid. Please take photos again.");
      //   resetCapture();
      // }
    } catch (error) {
      console.error("Error processing or validating images:", error);
      message.error("An error occurred. Please try again.");
      resetCapture();
    } finally {
      setCapturing(false);
    }
  };

  const resetCapture = () => {
    setFrontImage(null);
    setSideImage(null);
    setCurrentType("front");
    setCurrentOptions(frontCameraOptions);
    setIsCorrectStand(false);
    setShowTimer(false);
    setShowImage(false);
  };

  const handleOnCorrectStand = () => {
    setIsCorrectStand(true);
  };

  const handleOnWrongStand = () => {
    setIsCorrectStand(false);
  };

  const handleContinueWithout = () => {
    setShowCaptureButton(true);
    setContinueWithoutAccess(true);
  };

  const handleCaptureButton = () => {
    setIsCorrectStand(true);
    setShowCaptureButton(false);
  };

  const handleMediaError = (error: any) => {
    measurementContext.handleCameraAccess(false);
    if (error.toString().includes("dismissed")) {
      measurementContext.handleCameraErrorMessage("Camera access is not enabled. Check camera access or choose photo from gallery");
      setLoading(false);
    } else if (error.toString().includes("denied")) {
      measurementContext.handleCameraErrorMessage("You do not have camera access. Check camera access or choose photo from gallery");
      setLoading(false);
    } else if (error.toString().includes("Requested device not found")) {
      measurementContext.handleCameraErrorMessage("No camera found,  choose photo from gallery");
      setLoading(false);
    } else {
      measurementContext.handleCameraErrorMessage("No camera found,  choose photo from gallery");
    }
  };

  const onMediaStream = (stream: MediaStream) => {
    setCameraError(null);
    setLoading(false);
    setShowDegree(true);
    measurementContext.handleCameraIsReady(true);
  };

  if (capturing) {
    return <Loading />;
  }

  if (isSupported && !isPermissionGranted && !continueWithoutAccess) {
    return (
      <div className="fixed top-0 flex flex-col items-center justify-center w-full h-screen p-5 mt-2 bg-white z-1000">
        {!accessLoading && (
          <>
            <h1 className="text-lg font-bold text-center ">Permission denied</h1>
            <p className="text-sm text-center ">Enable motion and orientation from setting or continue without access</p>

            <div className="grid w-full grid-cols-2 gap-2">
              <Button onClick={handleContinueWithout} type="primary">
                continue
              </Button>
              <Button onClick={() => navigate(-1)} type="default">
                back
              </Button>
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="w-full h-screen pt-[3.75rem] z-50 fixed bottom-0 top-0 left-0">
      {isLoading && (
        <div className="flex items-center justify-center w-full h-screen bg-white">
          <Spin />
        </div>
      )}
      {!isSupported && !isLoading && !cameraError && (
        <div className="measurement-capture-wrapper">
          <button onClick={handleOnCorrectStand} className="measurement-capture-button" />
        </div>
      )}
      {!isLoading && (
        <div className="disabled-deg-title">
          <span className="inline-block p-2 px-4 bg-black rounded-full bg-opacity-80">{currentOptions.title}</span>
        </div>
      )}
      <div className={`device relative justify-center bg-black items-start ${isLoading || cameraError ? "hidden" : "flex"}`}>
        {currentOptions.hasDegree && showDegree && isPermissionGranted && (
          <StandMobileDegree
            isPermissionGranted={isPermissionGranted}
            isSupported={isSupported}
            orientation={orientation}
            type={currentType}
            onSuccess={handleOnCorrectStand}
            message={currentOptions.degreeText}
            onError={handleOnWrongStand}
          />
        )}

        <Webcam
          audio={false}
          className="camera"
          screenshotFormat="image/jpeg"
          mirrored={facingMode === "user"}
          ref={webcamRef}
          videoConstraints={videoConstraints}
          onUserMedia={onMediaStream}
          onUserMediaError={handleMediaError}
        />

        {!cameraError && hasBackCam && (
          <div className="fixed bottom-5 right-5 size-12 z-[9999]">
            <button onClick={toggleCamera} className="bg-white/60 rounded-full p-2 *:size-full ">
              <CameraSwitchIcon />
            </button>
          </div>
        )}

        {showTimer && <CountTimer count={currentOptions.timerCount} handleCapture={handleCapture} />}

        {!cameraError && <img src={currentOptions.correctStandImage} className={`stand-image z-20 absolute top-1/2 -translate-y-1/2 ${showImage ? "" : "hidden"}`} alt="" />}

        {!isLoading && !cameraError && (
          <>
            <img src={currentOptions.correctStandImage} className={`stand-image z-20 absolute top-1/2 -translate-y-1/2 ${showImage ? "" : "hidden"}`} alt="" />
          </>
        )}

        {!cameraError && !showCaptureButton && <CaptureButton onClick={handleCaptureButton} />}
      </div>
    </div>
  );
};

const CountTimer = ({ count, handleCapture }: any) => {
  const [timer, setTimer] = useState(count);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleCapture();
    }, count * 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer >= 1) {
        setTimer(timer - 1);
        if (timer - 1 === 0) {
        }
      }
    }, 1000);

    //Clearing the interval
    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  if (timer === 0) {
    return null;
  }

  return (
    <>
      <div className="relative z-50 timer">
        <AiOutlineLoading3Quarters className="absolute z-40 w-full h-full drop-shadows-sm animate-spin" />
        <span className="absolute z-50 text-4xl font-bold drop-shadows-sm">{timer}</span>
      </div>
    </>
  );
};

export default BodyMeasurementCamera;
