import { Button } from "antd";
import NotFoundImage from "../../images/notfound.svg";
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <img src={NotFoundImage} className=" w-[12.5rem] lg:w-[18.75rem] h-auto" alt="" />
      <h1 className="mt-4 text-2xl">Page not found</h1>
      <Link to={"/en/dashboard"}>
        <Button type="primary" className="mt-5 ">
          Back to home
        </Button>
      </Link>
    </div>
  );
};
export default NotFound;
