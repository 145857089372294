import React from "react";

interface sdkContextInterface {
  loading: boolean;
  handleLoading: Function;
  token: string | null;
  handleLogin: Function;
  handleLogout: Function;
  callback: string | null;
  serviceType: string | null;
}

const SdkContext = React.createContext<sdkContextInterface>({
  loading: false,
  token: null,
  callback: null,
  serviceType: null,
  handleLogin: () => {},
  handleLoading: () => {},
  handleLogout: () => {},
});

export default SdkContext;
