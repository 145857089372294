import { useState, useEffect, useRef, useCallback } from "react";
import * as faceapi from "face-api.js";

const useImageProcessor = () => {
  const [isModelLoaded, setIsModelLoaded] = useState(false);
  const net = useRef<faceapi.TinyFaceDetectorOptions | null>(null);

  const loadModels = useCallback(async () => {
    const MODEL_URL = `${process.env.PUBLIC_URL}/models`;
    try {
      await faceapi.loadTinyFaceDetectorModel(MODEL_URL);
      net.current = new faceapi.TinyFaceDetectorOptions();
      setIsModelLoaded(true);
      console.log("Face detection model loaded successfully");
    } catch (error) {
      console.error("Error loading face detection model:", error);
    }
  }, []);

  useEffect(() => {
    loadModels();
  }, [loadModels]);

  const processImage = async (base64Image: string): Promise<string> => {
    const img = new Image();
    img.src = base64Image;
    await new Promise((resolve) => (img.onload = resolve));

    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      throw new Error("Failed to get canvas context");
    }

    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

    if (!isModelLoaded || !net.current) return base64Image;

    const detections = await faceapi.detectAllFaces(canvas, new faceapi.TinyFaceDetectorOptions(net.current));

    detections.forEach((detection) => {
      const { x, y, width, height } = detection.box;
      ctx.filter = "blur(15px)";
      ctx.drawImage(canvas, x, y, width, height, x, y, width, height);
    });

    ctx.filter = "none";

    return canvas.toDataURL();
  };

  return { isModelLoaded, processImage };
};

export default useImageProcessor;
