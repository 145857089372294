import { Layout } from "antd";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import React, { useContext } from "react";
import AppContext from "../utilities/contexts/appContext";
import BottomNavigation from "./BottomNavigation";
import i18next from "i18next";

const AppLayout = () => {
  const { lang } = useParams();
  const location = useLocation();
  const { Content } = Layout;
  const appContext = useContext(AppContext);

  React.useEffect(() => {
    appContext.handleStep(0);

    window.scrollTo(0, 0);
    appContext.handleCapturing(false);
  }, [location]);

  React.useEffect(() => {
    i18next.changeLanguage(lang).then((r) => {
      appContext.handleDirection(i18next.dir(lang));
      appContext.handleLang(lang);
    });
  }, []);

  if (lang !== "en" && lang !== "fa") {
    return <Navigate to={"notfound"} />;
  }
  return (
    <div>
      <Content className={`body-${appContext.direction}`}>
        <Outlet />
      </Content>

      {!appContext.capturing && appContext.user && appContext.token && <BottomNavigation />}
    </div>
  );
};
export default AppLayout;
