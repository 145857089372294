import { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import HomeIcon from "./icons/HomeIcon";
import ServiceIcon from "./icons/ServicesIcon";
import ProfileIcon from "./icons/ProfileIcon";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const BottomNavigation = () => {
  const { t } = useTranslation();
  return (
    <div className="bottom-navigation lg:hidden  fixed bottom-0  w-full flex  py-2.5 flex justify-center items-center left-0 bg-white ">
      <BottomIcon icon={<HomeIcon className="bottom-icon" />} to={`/${i18next.language}/dashboard`} title={t("bottomNavigation.home")} />

      <BottomIcon icon={<ServiceIcon className="bottom-icon measure-icon" />} to={`/${i18next.language}/dashboard/services`} title={t("bottomNavigation.services")} />
      <BottomIcon icon={<ProfileIcon className="bottom-icon" />} to={`/${i18next.language}/dashboard/editProfile`} title={t("bottomNavigation.profile")} />
    </div>
  );
};
type bottomIconProps = {
  to: string;
  icon: ReactNode;
  title?: string;
};
const BottomIcon = (props: bottomIconProps) => {
  const location = useLocation();

  const active = location.pathname === props.to;
  return (
    <Link className={`mx-5 flex justify-center items-center flex-col  ${active ? "active-icon" : "default-icon"}`} to={props.to}>
      {props.icon}
      <span className="mt-[2px] text-sm">{props.title}</span>
    </Link>
  );
};
export default BottomNavigation;
