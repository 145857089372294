function toDataURL(url: any, callback: any) {
  let xhr = new XMLHttpRequest();
  xhr.onload = function () {
    let reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}
function reduceBase64ImageSize(base64: string, maxWidth: number, quality: number, callback: Function) {
  const img = new Image();
  img.src = base64;
  img.onload = function () {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const scaleSize = maxWidth / img.width;
    canvas.width = maxWidth;
    canvas.height = img.height * scaleSize;
    if (ctx) {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      const newBase64 = canvas.toDataURL("image/jpeg", quality);
      console.log(newBase64);
      callback(newBase64);
    }
  };
}

function handleDirection(locale: any) {
  const isPersian = locale === "fa";

  return isPersian ? "rtl" : "ltr";
}
function handleLanguage() {
  const isPersian = window.location.hostname.includes(".ir");
  return isPersian ? "fa" : "en";
}
async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: "image/png" });
}
function fileToBase64(file: File, callback: (base64Data: string) => void) {
  // Create a new FileReader object
  var reader = new FileReader();

  // Set the onload event handler
  reader.onload = function (event) {
    if (event.target) {
      const base64Data = event.target.result + "";
      callback(base64Data);
    }
  };

  // Read the file as a data URL
  reader.readAsDataURL(file);
}
function isIOS() {
  return (
    ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

export { toDataURL, isIOS, reduceBase64ImageSize, handleLanguage, handleDirection, dataUrlToFile, fileToBase64 };
