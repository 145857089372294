import Webcam from "react-webcam";
import { useContext, useEffect, useRef, useState } from "react";
import StandMobileDegree from "../StandMobileDegree";
import useDeviceOrientation from "../../utilities/hooks/useDeviceOrientation";
import bodyMeasurementCameraOptions from "../../utilities/types/bodyMeasurementCameraOptions";
import degreeAudioSrc from "../../sounds/90Degrees.wav";
import timerAudioSrc from "../../sounds/Timer.mp3";
import fullViewCorrectAudioSrc from "../../sounds/fullView.wav";
import fullViewCorrectImage from "../../images/measurement/full_side.png";
import sideViewCorrectAudioSrc from "../../sounds/sideView.wav";
import sideViewCorrectImage from "../../images/measurement/half_side.png";
import Loading from "../Loading";
import MeasurementCameraContext from "../../utilities/contexts/measurementCameraContext";
import Timer from "../measurementCamera/Timer";
import CaptureButton from "../measurementCamera/CaptureButton";
import MotionAccessCard from "../measurementCamera/MotionAccessCard";
import { useNavigate } from "react-router-dom";
import { Button, message } from "antd";
import { sendImagesToServer } from "../../utilities/Api/imageValidationService";
import CameraSwitchIcon from "../icons/CameraSwitchIcon";
import { hasBackCamera } from "../../utilities/cameraUtils";
import { processAndCompressImage } from "../imageProcessing";
import useImageProcessor from "../../utilities/hooks/useImageProcessor";

const frontCameraOptions: bodyMeasurementCameraOptions = {
  title: "Front Pose",
  degreeAudioSrc: degreeAudioSrc,
  timerAudioSrc: timerAudioSrc,
  correctStandAudioSrc: fullViewCorrectAudioSrc,
  timerCount: 7,
  captureStartTime: 8,
  correctStandImage: fullViewCorrectImage,
  degreeText: "Please set your phone on 90Deg",
  hasDegree: true,
};
const sideCameraOptions: bodyMeasurementCameraOptions = {
  title: "Side Pose",
  degreeAudioSrc: degreeAudioSrc,
  captureStartTime: 8,
  timerAudioSrc: timerAudioSrc,
  correctStandAudioSrc: sideViewCorrectAudioSrc,
  timerCount: 7,
  correctStandImage: sideViewCorrectImage,
  degreeText: "Please set your phone on 90 Deg",
  hasDegree: true,
};

const FACING_MODE_USER = "user";

interface Props {
  onComplete: Function;
  onError: Function;
  onReset: Function;
  onProcessing: (isProcessing: boolean) => void;
  type: "frontPose" | "sidePose";
}

const BodyMeasurementCamera = ({ onComplete, onError, onReset, onProcessing, type }: Props) => {
  const { isPermissionGranted, requestPermission, isSupported, orientation } = useDeviceOrientation();
  const navigate = useNavigate();

  const [frontPosePhoto, setFrontPosePhoto] = useState<string | null>(null);
  const [sidePosePhoto, setSidePosePhoto] = useState<string | null>(null);
  const [continueWithoutAccess, setContinueWithoutAccess] = useState(false);
  const [currentType, setCurrentType] = useState(type);
  const [currentOptions, setCurrentOptions] = useState<bodyMeasurementCameraOptions>(type === "frontPose" ? frontCameraOptions : sideCameraOptions);
  const [isLoading, setLoading] = useState(true);
  const [cameraError, setCameraError] = useState<null | string>(null);
  const [showImage, setShowImage] = useState(false);
  const [isCorrectStand, setIsCorrectStand] = useState(false);
  const [timeoutId, setTimeoutId] = useState<any>(null);
  const [showTimer, setShowTimer] = useState(false);
  const [showDegree, setShowDegree] = useState(false);
  const [showCaptureButton, setShowCaptureButton] = useState(!isPermissionGranted);
  const [capturing, setCapturing] = useState(false);
  const [takingPhoto, setTakingPhoto] = useState(false);
  const [accessLoading, setAccessLoading] = useState(false);
  const [facingMode, setFacingMode] = useState<"user" | "environment">(FACING_MODE_USER);
  const [hasBackCam, setHasBackCam] = useState(false);

  const { processImage } = useImageProcessor();

  const webcamRef = useRef<Webcam>(null);

  const measurementCameraContext = useContext(MeasurementCameraContext);

  const videoConstraints = {
    aspectRatio: 4 / 2,
    facingMode: facingMode === "user" ? facingMode : { exact: facingMode },
  };

  useEffect(() => {
    setAccessLoading(true);
    requestPermission().then((response) => {
      setAccessLoading(false);
      if (response === undefined) {
      }
    });
  }, []);

  useEffect(() => {
    async function checkBackCamera() {
      const result = await hasBackCamera();
      setHasBackCam(result);
    }
    checkBackCamera();
  }, []);

  useEffect(() => {
    setCurrentType(type);
    setCurrentOptions(type === "frontPose" ? frontCameraOptions : sideCameraOptions);
    resetCapture();
  }, [type]);

  useEffect(() => {
    if (isCorrectStand) {
      setShowImage(true);
      const id = setTimeout(() => {
        //timer start counting
        measurementCameraContext.handlePlaySound(0);

        setShowTimer(true);
      }, currentOptions.captureStartTime * 1000);
      setTimeoutId(id);
    } else {
      // measurementCameraContext.handlePlaySound(3);
      setShowTimer(false);
      setShowImage(false);
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null);
      }
    }
  }, [isCorrectStand, currentType]);

  useEffect(() => {
    if (frontPosePhoto) {
      setTimeout(() => {
        setCapturing(false);
      }, 300);
    }
  }, [frontPosePhoto]);

  useEffect(() => {
    if (sidePosePhoto && !capturing) {
      if (frontPosePhoto && sidePosePhoto) {
        onComplete(frontPosePhoto, sidePosePhoto);
      }
    }
  }, [sidePosePhoto]);

  const toggleCamera = () => {
    setFacingMode((currentMode) => (currentMode === "user" ? "environment" : "user"));
  };

  const handleOnCorrectStand = () => {
    setIsCorrectStand(true);
  };

  const handleOnWrongStand = () => {
    setIsCorrectStand(false);
  };

  const handleCapture = async () => {
    if (webcamRef && webcamRef.current) {
      const img = webcamRef.current?.getScreenshot() || "";
      if (currentType === "frontPose") {
        setFrontPosePhoto(img);
        onComplete(img, null);
      } else {
        setSidePosePhoto(img);
        await processAndSendImages(frontPosePhoto, img);
      }
    }
  };

  const processAndSendImages = async (frontImg: string | null, sideImg: string) => {
    if (!frontImg || !sideImg) {
      message.error("Error: Missing images. Please try again.");
      onReset();
      return;
    }

    setCapturing(true);
    try {
      const processFrontImg = await processImage(frontImg);

      setFrontPosePhoto(processFrontImg);
      setSidePosePhoto(sideImg);

      // const response = await sendImagesToServer(processedFrontImage, processedSideImage);

      // if (response.isValid) {
      //   measurementContext.handleMeasurement(processedFrontImage, processedSideImage);
      // } else {
      //   message.warning(response.message || "Images are not valid. Please take photos again.");
      //   resetCapture();
      // }
    } catch (error) {
      console.error("Error processing or validating images:", error);
      message.error("An error occurred. Please try again.");
      onError("An error occurred while processing images.");
      onReset();
    } finally {
      setCapturing(false);
    }
  };

  const resetCapture = () => {
    setFrontPosePhoto(null);
    setSidePosePhoto(null);
    setIsCorrectStand(false);
    setShowImage(false);
    setShowTimer(false);
    if (timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
  };

  const handleContinueWithout = () => {
    setShowCaptureButton(true);
    setContinueWithoutAccess(true);
  };

  const handleMediaError = (error: any) => {
    console.log(error);
    if (error.toString().includes("dismissed")) {
      onError("Camera access is not enabled. Check camera access or choose photo from gallery");
    } else if (error.toString().includes("denied")) {
      onError("You do not have camera access. Check camera access or choose photo from gallery");
    } else if (error.toString().includes("Requested device not found")) {
      onError("No camera found, choose photo from gallery");
    } else {
      onError("No camera found, choose photo from gallery");
    }
    setLoading(false);
  };

  const onMediaStream = (stream: MediaStream) => {
    setCameraError(null);
    setLoading(false);
    setShowDegree(true);
    console.log(stream);
    measurementCameraContext.handleCameraIsReady(true);
  };

  const handleTakePhoto = () => {
    setTakingPhoto(true);
    setIsCorrectStand(true);
  };

  if (capturing) {
    return <Loading />;
  }

  if (isSupported && !isPermissionGranted && !continueWithoutAccess) {
    return <MotionAccessCard loading={accessLoading} onContinue={handleContinueWithout} canBack={false} />;
  }

  return (
    <div>
      {isLoading && <Loading />}

      {!takingPhoto && (
        <div className="hidden measurement-capture-wrapper lg:block">
          <button onClick={handleTakePhoto} className="measurement-capture-button" />
        </div>
      )}

      {!isSupported && !cameraError && null}

      {!isLoading && (
        <div className="disabled-deg-title">
          <span className="inline-block p-2 px-4 bg-black rounded-full bg-opacity-80">{currentOptions.title}</span>
        </div>
      )}

      <div className={`device relative justify-center bg-black items-start ${isLoading || cameraError ? "hidden" : "flex"}`}>
        {currentOptions.hasDegree && showDegree && isPermissionGranted && (
          <StandMobileDegree
            isPermissionGranted={isPermissionGranted}
            isSupported={isSupported}
            orientation={orientation}
            type={currentType}
            onSuccess={handleOnCorrectStand}
            message={currentOptions.degreeText}
            onError={handleOnWrongStand}
            isSDK
          />
        )}

        <Webcam
          audio={false}
          className="object-contain camera"
          screenshotFormat="image/jpeg"
          mirrored={facingMode === "user"}
          ref={webcamRef}
          videoConstraints={videoConstraints}
          onUserMedia={onMediaStream}
          onUserMediaError={(error) => {
            console.log(error);
            handleMediaError(error);
          }}
        />

        {!cameraError && hasBackCam && (
          <div className="fixed bottom-5 right-5 size-12 z-[9999]">
            <button onClick={toggleCamera} className="bg-white/60 rounded-full p-2 *:size-full ">
              <CameraSwitchIcon />
            </button>
          </div>
        )}

        {!cameraError && <img src={currentOptions.correctStandImage} className={`stand-image z-50 absolute top-1/2 -translate-y-1/2 ${showImage ? "" : "hidden"}`} alt="" />}

        {showCaptureButton && !isPermissionGranted && <CaptureButton onClick={handleCapture} />}

        {showTimer && <Timer count={currentOptions.timerCount} onComplete={handleCapture} />}
      </div>

      {capturing && <Loading />}
    </div>
  );
};

export default BodyMeasurementCamera;
