interface Props {
  className?: string;
}
const profileIcon = (props: Props) => {
  return (
    <svg className={`icon ${props.className}`} xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20">
      <path
        id="profile-2036083"
        d="M5.885,12.767a25.264,25.264,0,0,1,4.317,0,14.931,14.931,0,0,1,2.334.343c1.661.334,2.744.994,3.2,1.962a3,3,0,0,1-.026,2.525c-.463.968-1.547,1.628-3.234,1.971a14.958,14.958,0,0,1-2.325.334,19.482,19.482,0,0,1-2.281.1H7.6a.766.766,0,0,1,0-1.522h.6c.6-.007,1.2-.035,1.805-.088a14.233,14.233,0,0,0,2.115-.3c1.2-.264,1.949-.642,2.185-1.144a1.407,1.407,0,0,0,0-1.214c-.236-.51-.988-.906-2.159-1.144a13.609,13.609,0,0,0-2.15-.308,22.39,22.39,0,0,0-4.064,0,14.348,14.348,0,0,0-2.124.3c-1.2.264-1.94.642-2.185,1.144a1.46,1.46,0,0,0-.131.607,1.5,1.5,0,0,0,.131.616,2.54,2.54,0,0,0,1.748,1.029h0l.1.028a.765.765,0,0,1-.486,1.451,3.871,3.871,0,0,1-2.7-1.848,2.966,2.966,0,0,1,0-2.525c.463-.994,1.547-1.637,3.216-1.98A16.035,16.035,0,0,1,5.885,12.767ZM5.965.407a5.3,5.3,0,0,1,5.8,1.166,5.385,5.385,0,0,1,1.148,5.843A5.317,5.317,0,0,1,2.677,5.359h0l0-.231A5.357,5.357,0,0,1,5.965.407ZM7.991,1.531A3.789,3.789,0,0,0,5.3,2.649a3.854,3.854,0,0,0,1.23,6.26,3.792,3.792,0,0,0,4.156-.826,3.855,3.855,0,0,0,.83-4.182A3.811,3.811,0,0,0,7.991,1.531Z"
        fill="#200e32"
      />
    </svg>
  );
};
export default profileIcon;
