import AccessDeniedImage from "../../../images/accessDenied.svg";
const SdkAccessDenied = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <img className="w-[250px] lg:w-[350px] h-auto" src={AccessDeniedImage} alt="" />
      <h1 className="mt-5 text-2xl text-bold ">ACCESS DENIED </h1>
    </div>
  );
};
export default SdkAccessDenied;
