import ResultCard2 from "../measurement/ResultCard2";
import TopTitle from "../TopTitle";
import React from "react";
import { Button } from "antd";

interface Props {
  result: any;
  returnUrl: string | null;
}
const Result = (props: Props) => {
  const { result, returnUrl } = props;
  const handleNavigateToUrl = () => {
    if (returnUrl) window.location.href = returnUrl;
  };
  return (
    <div className="w-full ">
      <TopTitle title="RESULT" disableBack={true} />
      <ResultCard2 data={result} />
      {returnUrl && (
        <div className="fixed bottom-0 z-50 flex items-center justify-center w-full px-3 py-3 bg-white shadow-xl">
          <div className="w-full md:w-6/12 lg:w-4/12">
            <Button onClick={handleNavigateToUrl} className="w-full" type="primary" size="large">
              BACK TO APP
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
export default Result;
