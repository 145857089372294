import React, { useContext, useEffect, useState } from "react";
import fullView_1 from "../../../images/guide/full_1.webp";
import fullView_2 from "../../../images/guide/full_2.webp";
import sideView1 from "../../../images/guide/side_1.webp";
import Guide from "../../Guide";
import GetHeight from "../../sdk/GetHeight";
import DataContext from "../../../utilities/contexts/sdk/dataContext";
import ChooseImage from "../../sdk/ChooseImage";
import sizeMeasurementApi from "../../../utilities/Api/sdk/sizeMeasurement";
import SdkContext from "../../../utilities/contexts/sdk/sdkContext";
import { Steps, notification } from "antd";
import Result from "../../sdk/Result";
import SdkApi from "../../../utilities/Api/sdk/Api";
import Loading from "../../Loading";
import { Navigate, useNavigate } from "react-router-dom";
import ConsentPage from "../../measurement/ConsentPage";
import SizeMeasurementApi from "../../../utilities/Api/sdk/sizeMeasurement";
import SdkIntroduction from "../../sdk/SdkIntroduction";

interface step {
  title: string;
  description?: string;
  image: string;
  texts: Array<string>;
}

const defaultSteps = {
  INTRODUCTION: "Introduction",
  GUIDE: "Guide",
  CONSENT: "Consent",
  FRONT_POSE: "FrontPose",
  SIDE_POSE: "SidePose",
  GET_HEIGHT: "GetHeight",
  RESULT: "Result",
};

const MeasurementGuid: Array<step> = [
  {
    title: "Stand Straight",
    description: "Before taking a photo, pay attention to the following points",
    image: fullView_1,
    texts: ["Place the camera on the table at 90 degrees", "Stand straight in front of the camera", "Capture your complete height"],
  },
  {
    title: "Front Pose",
    description: "Before taking a photo, pay attention to the following points",
    image: fullView_2,
    texts: ["Let your hands hang by your sides", "Don’t wear baggy clothes", "Don’t  hide your body shape in clothes"],
  },
  {
    title: "Side Pose",
    description: "Before taking a photo, pay attention to the following points",
    image: sideView1,
    texts: ["Do not stand at an angle", "Hang your arms on your thighs’ side"],
  },
];
const SizeMeasurement = () => {
  const [step, setStep] = useState(defaultSteps.INTRODUCTION);
  const [height, setHeight] = useState<number | null>(null);
  const [frontPoseImage, setFrontPoseImage] = useState<string | null>(null);
  const [sidePoseImage, setSidePoseImage] = useState<string | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [result, setResult] = useState<any>(null);
  const [api, contextHolder] = notification.useNotification();
  const sdkContext = useContext(SdkContext);
  const [loading, setLoading] = useState(false);
  const [isCapturing, setIsCapturing] = useState(false);
  const [isProcessingImages, setIsProcessingImages] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (frontPoseImage) {
      console.log("front ready", frontPoseImage);
      setStep(defaultSteps.SIDE_POSE);
    }
  }, [frontPoseImage]);

  useEffect(() => {
    if (sidePoseImage && !isProcessingImages) {
      handleStep(defaultSteps.GET_HEIGHT);
    }
  }, [sidePoseImage, isProcessingImages]);

  useEffect(() => {
    if (height && frontPoseImage && sidePoseImage) {
      handleSizeMeasurement(frontPoseImage, sidePoseImage, height).then((r) => console.log(r));
    }
  }, [height]);

  useEffect(() => {
    if (step) {
      switch (step) {
        case defaultSteps.GUIDE: {
          setCurrentIndex(0);
          break;
        }
        case defaultSteps.CONSENT: {
          setCurrentIndex(1);
          break;
        }
        case defaultSteps.FRONT_POSE: {
          setFrontPoseImage(null);
          setSidePoseImage(null);
          setCurrentIndex(2);
          break;
        }
        case defaultSteps.SIDE_POSE: {
          setCurrentIndex(3);
          setSidePoseImage(null);
          break;
        }
        case defaultSteps.GET_HEIGHT: {
          setCurrentIndex(4);
          setIsCapturing(false);
          break;
        }
        case defaultSteps.RESULT: {
          setCurrentIndex(5);
          break;
        }
      }
    }
  }, [step]);

  const handleStep = (newStep: string) => {
    setStep(newStep);
  };

  const handleHeight = (newHeight: number | null) => {
    setHeight(newHeight);
  };

  const handleIntroductionComplete = () => {
    handleStep(defaultSteps.GUIDE);
  };

  const handleConsentAccept = () => {
    handleStep(defaultSteps.FRONT_POSE);
  };

  const handleConsentDecline = () => {
    navigate("/");
  };

  const handleFrontPoseImage = (newImage: string | null) => {
    setFrontPoseImage(newImage);
  };

  const handleSidePoseImage = (newImage: string | null) => {
    setSidePoseImage(newImage);
  };

  const handleSizeMeasurement = async (frontPose: string, sidePose: string, height: number) => {
    const data = {
      img_full_view_body: frontPose.split(",")[1],
      img_side_view_body: sidePose.split(",")[1],
      present_height: height,
    };

    sdkContext.handleLoading(true);
    const response = await sizeMeasurementApi({ token: sdkContext.token, data });
    console.log(response, "------------");
    if (response && response.ok) {
      if (sdkContext.callback) {
        setLoading(true);
        SdkApi.post(sdkContext.callback, { ...response.data?.result })
          .then((res) => {
            console.log(res);
          })
          .finally(() => {
            setLoading(false);
          });
      }

      setResult(response.data?.result);
      setStep(defaultSteps.RESULT);
      sdkContext.handleLoading(false);
    } else {
      api.error({
        message: response.message,
        placement: "topRight",
      });
      sdkContext.handleLoading(false);
    }
  };

  const handleCapturing = (value: boolean) => {
    setIsCapturing(value);
    setIsProcessingImages(value);
  };

  const handleReset = () => {
    setStep(defaultSteps.FRONT_POSE);
    setFrontPoseImage(null);
    setSidePoseImage(null);
    setHeight(null);
    setResult(null);
    setCurrentIndex(0);
    setIsCapturing(true);
  };

  const handleTakePhoto = (frontPose: string | null, sidePose: string | null) => {
    if (frontPose) {
      setFrontPoseImage(frontPose);
      handleStep(defaultSteps.SIDE_POSE);
    } else if (sidePose) {
      setSidePoseImage(sidePose);
      handleStep(defaultSteps.GET_HEIGHT);
    }
  };

  const handleHeightSubmit = async () => {
    if (height && frontPoseImage && sidePoseImage) {
      try {
        sdkContext.handleLoading(true);
        const response = await SizeMeasurementApi({
          token: sdkContext.token,
          data: {
            img_full_view_body: frontPoseImage,
            img_side_view_body: sidePoseImage,
            present_height: height,
          },
        });
        if (response && response.ok) {
          setResult(response.data?.result);
          handleStep(defaultSteps.RESULT);
        } else {
          api.error({
            message: "Error",
            description: response.message || "An error occurred during measurement.",
          });
        }
      } catch (error) {
        console.error("Error in size measurement:", error);
        api.error({
          message: "Error",
          description: "An unexpected error occurred. Please try again.",
        });
      } finally {
        sdkContext.handleLoading(false);
      }
    }
  };

  const currentStepIndex = Object.values(defaultSteps).indexOf(step);

  if (!sdkContext.token) {
    localStorage.clear();
    return <Navigate to={"/sdk/accessDenied"} />;
  }

  return (
    <div>
      {contextHolder}
      <DataContext.Provider
        value={{
          handleTakePhoto,
          height,
          frontPoseImage,
          sidePoseImage,
          handleHeight,
          handleFrontPoseImage,
          handleSidePoseImage,
          isCapturing,
          handleCapturing,
          handleReset,
        }}
      >
        {contextHolder}
        {!loading && !isCapturing && (
          <div className="hidden w-11/12 p-5 mx-auto bg-white rounded-lg md:block md:w-6/12 lg:w-4/12">
            <Steps current={currentStepIndex} className="mb-8">
              <Steps.Step title="Consent" />
              <Steps.Step title="Guide" />
              <Steps.Step title="Photos" />
              <Steps.Step title="Height" />
              <Steps.Step title="Result" />
            </Steps>
          </div>
        )}

        {step === defaultSteps.INTRODUCTION && <SdkIntroduction onComplete={handleIntroductionComplete} />}
        {step === defaultSteps.GUIDE && <Guide callback={() => handleStep(defaultSteps.CONSENT)} slides={MeasurementGuid} />}
        {step === defaultSteps.CONSENT && <ConsentPage onAccept={handleConsentAccept} onDecline={handleConsentDecline} />}
        {(step === defaultSteps.FRONT_POSE || step === defaultSteps.SIDE_POSE) && <ChooseImage type={step === defaultSteps.FRONT_POSE ? "frontPose" : "sidePose"} />}
        {step === defaultSteps.GET_HEIGHT && <GetHeight handleBack={() => handleStep(defaultSteps.FRONT_POSE)} hasBack={true} onSubmit={handleHeightSubmit} />}
        {step === defaultSteps.RESULT && result && loading && <Loading message="sending result to platform" />}
        {step === defaultSteps.RESULT && result && !loading && <Result returnUrl={sdkContext.callback} result={result} />}
      </DataContext.Provider>
    </div>
  );
};
export default SizeMeasurement;
